.wrapper--1P-ga {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  width: 100%;
  overflow: hidden;
  border: 1px solid hsla(0, 0%, 85%, 1);
  border-radius: 4px;
  transition: all 0.25s ease-in-out; }

.decrement-button--3Ky0B,
.increment-button--1hgNs {
  position: relative;
  min-width: 58px;
  height: 50px;
  background-color: hsla(0, 0%, 100%, 1);
  border: 0 none;
  outline: none;
  cursor: pointer; }

.decrement-button--3Ky0B[disabled]::after,
.increment-button--1hgNs[disabled]::after,
.decrement-button--3Ky0B[disabled]::before,
.increment-button--1hgNs[disabled]::before {
  background-color: hsla(0, 0%, 15%, 1); }

.decrement-button--3Ky0B::after,
.increment-button--1hgNs::after,
.decrement-button--3Ky0B::before,
.increment-button--1hgNs::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 14px;
  height: 2px;
  margin: -1px 0 0 -7px;
  font-size: 16px;
  background: hsla(0, 0%, 0%, 1);
  content: ''; }

.increment-button--1hgNs::before {
  transform: rotate(90deg); }

.decrement-button--3Ky0B:hover,
.increment-button--1hgNs:hover {
  background: hsla(0, 0%, 97%, 1); }

.value--1Kcr3 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 8px;
  overflow: hidden;
  color: hsla(0, 0%, 15%, 1);
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: hsla(0, 0%, 100%, 1); }
