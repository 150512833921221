.form--2r_Nj {
  background-color: hsla(0, 0%, 97%, 1);
  border-radius: 4px; }
  .form--white--nSf49 {
    background-color: hsla(0, 0%, 100%, 1); }
    @media (min-width: 990px) {
      .form--white__column---YzFz {
        flex-direction: column !important; } }
  .form--2r_Nj * {
    box-sizing: border-box; }
  .form__wrap--2pKvs {
    display: flex;
    flex-direction: column; }
  .form__row--Td-Nc {
    display: flex;
    flex-flow: row nowrap; }
  .form__column--1qWv5 {
    display: flex;
    flex: 1 1 100%;
    flex-flow: column wrap;
    align-items: flex-start; }
    @media (max-width: 989px) {
      .form__column--1qWv5 {
        display: block; } }
    @media (min-width: 990px) {
      .form__column--1qWv5 {
        flex-direction: row; } }
  .form__common-error--27C2y {
    display: block;
    color: hsla(360, 90%, 60%, 1);
    text-align: center; }

.form-phone-screen--2B20B,
.form-otp-screen--3VU26 {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  align-items: flex-start; }
  @media (min-width: 990px) {
    .form-phone-screen--2B20B,
    .form-otp-screen--3VU26 {
      flex-flow: row wrap;
      justify-content: space-between; } }
  .form-phone-screen__caption--91ivU,
  .form-otp-screen__caption--3nt_h {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    text-align: center; }
  @media (min-width: 990px) {
    .form-phone-screen__gap--3LQwN,
    .form-otp-screen__gap--3745E {
      display: none; } }
  .form-phone-screen__input-container--_VWZE, .form-phone-screen__button-container--2RZxY,
  .form-otp-screen__input-container--2LJ36,
  .form-otp-screen__button-container--1_Mvr {
    display: flex;
    flex-flow: row nowrap; }
  .form-phone-screen__button-container--2RZxY,
  .form-otp-screen__button-container--1_Mvr {
    margin-top: 10px; }
    @media (min-width: 990px) {
      .form-phone-screen__button-container--2RZxY,
      .form-otp-screen__button-container--1_Mvr {
        margin-top: 0; } }

.form-otp-screen__phone--ALz52 {
  white-space: nowrap; }

.form-callback--YMdZT {
  background-color: hsla(0, 0%, 97%, 1);
  border-radius: 4px; }
  .form-callback__row--2qYAY {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between; }
  .form-callback--YMdZT * {
    box-sizing: border-box; }
  .form-callback__wrap--2aSum {
    display: flex;
    flex-direction: column; }
  .form-callback__column--XJ9MA {
    display: flex;
    flex: 1 1 100%;
    flex-flow: column wrap;
    align-items: flex-start; }
    @media (max-width: 989px) {
      .form-callback__column--XJ9MA {
        display: block; } }
    @media (min-width: 990px) {
      .form-callback__column--XJ9MA {
        flex: 0;
        flex-grow: 0.46; } }
  .form-callback__common-error--23xIe {
    display: block;
    color: hsla(360, 90%, 60%, 1);
    text-align: center; }

.input-wrap--1T1Ho {
  position: relative;
  display: flex;
  flex-basis: 0;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  margin-top: 10px; }
  @media (min-width: 990px) {
    .input-wrap--1T1Ho {
      margin-top: 0;
      margin-right: 10px; } }
  .input-wrap--1T1Ho:first-child {
    margin-top: 0; }

.button-wrap--3X8uD {
  margin-top: 20px; }
  @media (min-width: 990px) {
    .button-wrap--3X8uD {
      margin-top: 0; } }

.alert--328f5 {
  color: hsla(360, 90%, 60%, 1); }

.disclaimer--3oNs9 {
  color: hsla(0, 0%, 65%, 1); }
  @media (min-width: 990px) {
    .disclaimer--3oNs9 {
      text-align: center; } }

.lamoda__header--2Rv4S {
  font-size: 40px;
  line-height: 40.5px;
  font-weight: 300; }
  @media (min-width: 990px) {
    .lamoda__header--2Rv4S {
      white-space: nowrap; } }
  .lamoda__header--lamodaRed--G8Kvs {
    color: #eb3300; }

.lamoda__column--1i9ba {
  display: flex;
  flex: 1 1 100%;
  flex-flow: column wrap;
  align-items: flex-start; }
  @media (max-width: 989px) {
    .lamoda__column--1i9ba {
      display: block; } }

.lamoda__subheader--1pbV9 {
  font-size: 17.5px;
  line-height: 20px;
  font-weight: 300; }

.lamoda__wrapper--15srP {
  margin-bottom: 32.5px; }

.lamoda__button-wrap--15O-- {
  position: relative;
  display: flex;
  flex-basis: 0;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 10px; }
  @media (max-width: 989px) {
    .lamoda__button-wrap--15O-- {
      width: 100%; } }
  @media (min-width: 990px) {
    .lamoda__button-wrap--15O-- {
      margin-top: 0;
      margin-right: 10px; } }
  .lamoda__button-wrap--15O--:first-child {
    margin-top: 0; }

.lamoda__recallNumber--1id50 {
  color: hsla(0, 0%, 15%, 1); }

.sell-goods-short-form__header--1IM-6 {
  font-size: 40px;
  line-height: 40.5px;
  font-weight: 300; }
  @media (min-width: 990px) {
    .sell-goods-short-form__header--1IM-6 {
      white-space: nowrap; } }
  .sell-goods-short-form__header--sellGoodsRed--du5Tr {
    color: #eb3300; }

.sell-goods-short-form__column--2vqJK {
  display: flex;
  flex: 1 1 100%;
  flex-flow: column wrap;
  align-items: flex-start; }
  @media (max-width: 989px) {
    .sell-goods-short-form__column--2vqJK {
      display: block; } }

.sell-goods-short-form__subheader--183q- {
  font-size: 17.5px;
  line-height: 20px;
  font-weight: 300; }

.sell-goods-short-form__wrapper--2hzKU {
  margin-bottom: 32.5px; }

.sell-goods-short-form__button-wrap--113hq {
  position: relative;
  display: flex;
  flex-basis: 0;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 10px; }
  @media (max-width: 989px) {
    .sell-goods-short-form__button-wrap--113hq {
      width: 100%; } }
  @media (min-width: 990px) {
    .sell-goods-short-form__button-wrap--113hq {
      margin-top: 0;
      margin-right: 10px; } }
  .sell-goods-short-form__button-wrap--113hq:first-child {
    margin-top: 0; }

.sell-goods-short-form__recallNumber--2_4ST {
  color: hsla(0, 0%, 15%, 1); }
