.input-container--2OtCh {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%; }
  .input-container__wrapper--2zd60 {
    position: relative;
    width: 100%;
    overflow: hidden;
    background: hsla(0, 0%, 100%, 1); }
    .input-container__wrapper--disabled--3laus {
      background: hsla(0, 0%, 97%, 1); }
  .input-container__input--4-le_, .input-container__placeholder--2Wte4 {
    box-sizing: border-box;
    padding-top: 0;
    padding-bottom: 0;
    font-weight: 300;
    font-size: 18px;
    border: none;
    border-bottom: 1px solid;
    -webkit-appearance: none;
    appearance: none; }
  .input-container__input--4-le_ {
    position: relative;
    z-index: 20;
    width: 100%;
    padding-left: 0;
    padding-right: 15px;
    background-color: hsla(0, 0%, 0%, 0);
    border-color: #888888;
    outline: none;
    transition: all 0.1s ease-in-out; }
    .input-container__input--4-le_:hover:not(:disabled) {
      cursor: text; }
    .input-container__input--4-le_:hover:not(.input-container__input--error--Nc1bA):not(:disabled):not(:focus) {
      border-color: hsla(0, 0%, 65%, 1); }
    .input-container__input--4-le_:focus:not(:disabled):not(.input-container__input--error--Nc1bA) {
      border-color: var(--color-primary); }
    .input-container__input--4-le_:disabled {
      color: hsla(0, 0%, 65%, 1);
      -webkit-text-fill-color: hsla(0, 0%, 65%, 1);
      border-color: hsla(0, 0%, 85%, 1);
      cursor: not-allowed; }
    .input-container__input--error--Nc1bA {
      border-color: hsla(360, 90%, 60%, 1); }
    .input-container__input--4-le_::-ms-clear {
      display: none; }
  .input-container__placeholder--2Wte4 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    color: hsla(0, 0%, 65%, 1);
    border-color: hsla(0, 0%, 0%, 0); }
    .input-container__placeholder--disabled--EHSXM {
      color: hsla(0, 0%, 65%, 1); }
    .input-container__placeholder--2Wte4 span {
      visibility: hidden; }
  .input-container__input--s--31cBt, .input-container__placeholder--s--3INP6 {
    height: 40px; }
  .input-container__input--m--2lZ4k, .input-container__placeholder--m--1U7HJ {
    height: 50px; }
  .input-container__error--yvZ19 {
    color: hsla(360, 90%, 60%, 1); }
