.wrapper--klSHe {
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0 15px;
  background: hsla(0, 0%, 100%, 1);
  border: 1px solid hsla(0, 0%, 85%, 1);
  border-radius: 4px; }
  .wrapper--klSHe:hover:not(:active) {
    border-color: hsla(0, 0%, calc(85% - 20%), 1); }
