:root {
  --color-lamoda-primary-h: 209;
  --color-lamoda-primary-s: 100%;
  --color-lamoda-primary-l: 57.6%;
  --color-lamoda-primary-a: 1; }

.button--2DKv7 {
  display: flex;
  flex: 0 0 auto;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  white-space: nowrap;
  text-decoration: none;
  border: 1px solid hsla(0, 0%, 0%, 0);
  border-radius: 4px;
  outline: none;
  transition: all 0.1s ease-in-out;
  -webkit-tap-highlight-color: hsla(0, 0%, 0%, 0);
  --color-lamoda-primary-h: 209;
  --color-lamoda-primary-s: 100%;
  --color-lamoda-primary-l: 57.6%;
  --color-lamoda-primary-a: 1; }
  .button--2DKv7:disabled {
    opacity: 0.4; }
  .button--2DKv7:hover:not(:disabled) {
    cursor: pointer; }
  .button--rounded--2B6yu {
    border-radius: 100px; }
  .button--floating--ffFnA {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 10%); }
  .button--height-small--11p4Q {
    height: 40px; }
  .button--height-medium--3QaVj {
    height: 50px; }
  .button--width-small--wIbKW {
    padding-right: 25px;
    padding-left: 25px; }
  .button--width-medium--2Kg__ {
    padding-right: 90px;
    padding-left: 90px; }
  .button--width-wide--1jTxj {
    flex: 1 1 auto;
    width: 100%; }

.button--primary--3lIvB {
  --back-lamoda-color-h-base: var(--color-lamoda-primary-h);
  --back-lamoda-color-s-base: var(--color-lamoda-primary-s);
  --back-lamoda-color-l-base: var(--color-lamoda-primary-l);
  --back-lamoda-color-a-base: var(--color-lamoda-primary-a);
  --back-color-h: var(--back-lamoda-color-h-base);
  --back-color-s: var(--back-lamoda-color-s-base);
  --back-color-l: var(--back-lamoda-color-l-base);
  --back-color-a: var(--back-lamoda-color-a-base);
  --color: hsla(0, 0%, 100%, 1);
  --svg-primary-color: var(--color);
  color: var(--color);
  background-color: hsla(var(--back-color-h), var(--back-color-s), var(--back-color-l), var(--back-color-a));
  border-color: hsla(0, 0%, 0%, 0); }
  .button--primary--3lIvB:hover:not(:disabled) {
    --back-color-l: calc(var(--back-lamoda-color-l-base) - 5%); }
  .button--primary-on-dark--3tBIC, .button--primary-on-light--2jRbY {
    --back-color-h-base: 0;
    --back-color-s-base: 0%;
    --back-color-l-base: 0%;
    --back-color-a-base: 1; }
    .button--primary-on-dark--3tBIC:hover:not(:disabled), .button--primary-on-light--2jRbY:hover:not(:disabled) {
      --back-color-l: calc(var(--back-lamoda-color-l-base) + 10%); }

.button--secondary--18lqO {
  --color: var(--color-primary);
  --svg-primary-color: var(--color);
  color: var(--color);
  background-color: hsla(0, 0%, 0%, 0);
  border-color: hsla(var(--color-lamoda-primary-h), var(--color-lamoda-primary-s), var(--color-lamoda-primary-l), var(--color-lamoda-primary-a)); }
  .button--secondary--18lqO:hover:not(:disabled), .button--secondary--18lqO:active, .button--secondary--18lqO:focus {
    color: hsla(0, 0%, 100%, 1);
    border-color: hsla(0, 0%, 0%, 0); }
  .button--secondary--18lqO:hover:not(:disabled) {
    background-color: hsla(var(--color-lamoda-primary-h), var(--color-lamoda-primary-s), calc(var(--color-lamoda-primary-l) - 5%), var(--color-lamoda-primary-a)); }
  .button--secondary--18lqO:active, .button--secondary--18lqO:focus {
    background-color: hsla(var(--color-lamoda-primary-h), var(--color-lamoda-primary-s), var(--color-lamoda-primary-l), var(--color-lamoda-primary-a)); }

.button--secondary-on-dark--1z-zR {
  --color: hsla(0, 0%, 100%, 1);
  --svg-primary-color: var(--color);
  color: var(--color);
  background-color: hsla(0, 0%, 0%, 0);
  border-color: hsla(0, 0%, 100%, 1); }
  .button--secondary-on-dark--1z-zR:hover:not(:disabled) {
    background-color: hsla(0, 0%, 0%, 0);
    border-color: hsla(0, 0%, calc(100% - 10%), 1); }

.button--secondary-on-light--36kkk {
  --color: hsla(0, 0%, 0%, 1);
  --svg-primary-color: var(--color);
  color: var(--color);
  background-color: hsla(0, 0%, 0%, 0);
  border-color: hsla(0, 0%, 0%, 1); }
  .button--secondary-on-light--36kkk:hover:not(:disabled), .button--secondary-on-light--36kkk:active, .button--secondary-on-light--36kkk:focus {
    color: hsla(0, 0%, 100%, 1);
    border-color: hsla(0, 0%, 0%, 0); }
  .button--secondary-on-light--36kkk:hover:not(:disabled) {
    background-color: hsla(0, 0%, calc(0% + 10%), 1); }
  .button--secondary-on-light--36kkk:active, .button--secondary-on-light--36kkk:focus {
    background-color: hsla(0, 0%, 0%, 1); }

.button--tertiary--1o15z {
  --color: hsla(0, 0%, 15%, 1);
  --svg-primary-color: var(--color);
  color: var(--color);
  background-color: hsla(0, 0%, 0%, 0);
  border-color: hsla(0, 0%, 85%, 1); }
  .button--tertiary--1o15z:hover:not(:disabled) {
    border-color: hsla(0, 0%, calc(85% - 20%), 1); }
